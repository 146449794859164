<template>
    <div class="section">
        <div class="container">
            <h1>Manage user</h1>

            <div v-if="user && user.Profile" class="columns">
                <div class="column is-4">
                    <o-field label="Username:">
                        <o-input type="text" v-model="user.UserName"></o-input>
                    </o-field>
                    <o-field label="Firstname:">
                        <o-input v-model="user.Profile.FirstName"></o-input>
                    </o-field>
                    <o-field label="Lastname:">
                        <o-input v-model="user.Profile.LastName"></o-input>
                    </o-field>
                    <o-field label="Company:">
                        <o-input v-model="user.Profile.Company"></o-input>
                    </o-field>
                    <o-field label="Phone:">
                        <o-input v-model="user.Profile.Phone"></o-input>
                    </o-field>
                    <o-field label="Fax:">
                        <o-input v-model="user.Profile.Fax"></o-input>
                    </o-field>
                </div>
                <div class="column is-4">
                    <o-field label="Address:">
                        <o-input v-model="user.Profile.Address"></o-input>
                    </o-field>
                    <o-field label="City:">
                        <o-input v-model="user.Profile.City"></o-input>
                    </o-field>
                    <o-field label="State:">
                        <o-select expanded v-model="user.Profile.StateId">
                            <option v-for="s in states" v-bind:key="s.Id" :value="s.Id">{{ s.Name }}</option>
                        </o-select>
                    </o-field>
                    <o-field label="Zipcode:">
                        <o-input v-model="user.Profile.Zip"></o-input>
                    </o-field>
                    <o-field>
                        <o-checkbox v-model="user.IsDisabled">Is Disabled?</o-checkbox>
                    </o-field>
                    <br />
                    <o-field>
                        <o-button class="button is-primary" @click="saveUser">Update User</o-button>
                    </o-field>
                </div>
                <div class="column is-4">
                    <div class="card">
                        <div class="card-content">
                            <h2>Roles</h2>
                            <div v-if="roles">
                                <o-field v-for="r in roles" v-bind:key="r.Id">
                                    <o-checkbox v-model="user.Roles" :native-value="r.Id">{{ r.Name }}</o-checkbox>
                                </o-field>
                            </div>
                            <div class="my-4">
                                <o-button class="button is-primary" @click="updateRoles">Update Roles</o-button>
                            </div>
                            <hr />
                            <h2>Reset Password</h2>

                            <o-button class="button is-secondary" @click="sendResetPassword">Send Forgot Password Email</o-button>
                            <div v-if="false">
                                <o-field label="New Password" :variant="passwordsMatch ? '' :'danger'">
                                    <o-input type="password" v-model="newPassword" placeholder="New Password"></o-input>
                                </o-field>
                                <o-field label="Confirm Password" :variant="passwordsMatch ? '' :'danger'" :message="passwordsMatch ? '' :'Your passwords must match.'">
                                    <o-input type="password" v-model="confirmPassword" placeholder="Confirm Password"></o-input>
                                </o-field>
                                <br />
                                <o-button class="button is-primary" @click="updatePassword">Update Password</o-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="my-6">
                <h2>Quote history</h2>
                <quote-history :userId="uid"></quote-history>
            </div>

            <div class="my-6">
                <h2>Saved grilles</h2>
                <saved-grilles :userId="uid"></saved-grilles>
            </div>

            <div class="my-6">
                <h2>Saved schedules</h2>
                <saved-schedules :userId="uid"></saved-schedules>
            </div>
        </div>
    </div>
</template>


<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import { quoteHistory, savedGrilles, savedSchedules } from "@/components";
    export default {
        components: {
            quoteHistory,
            savedGrilles,
            savedSchedules
        },
        props: {
            uid: String
        },
        emits: [],
        setup(props) {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const user = ref({});
            const reset = ref({});
            const states = ref([]);
            const roles = ref([]);
            const newPassword = ref("");
            const confirmPassword = ref("");

            const getUser = async () => {
                try {
                    var response = await $http.get("/api/user/admin/" + props.uid);
                    user.value = response.data;
                } catch (err) {
                    $error("Failed to get user.");
                }
            }

            const getStates = async () => {
                try {
                    var response = await $http.get("/api/state");
                    states.value = response.data;
                } catch (err) {
                    $error("Failed to get states.");
                }
            }

            const getRoles = async () => {
                try {
                    var response = await $http.get("/api/role");
                    roles.value = response.data;
                } catch (err) {
                    $error("Failed to get roles.");
                }
            }

            onMounted(async () => {
                await getUser();
                await getStates();
                await getRoles();
            });

            const saveUser = async () => {
                try {
                    var response = await $http.post("/api/user/admin/update", user.value);
                    if (response.data.User.Id) {
                        $success("Successfully updated the user's info.");
                    } else {
                        $error("Failed to save user.");
                    }
                } catch (err) {
                    $error("Failed to save user.");
                }
            }

            const updateRoles = async () => {
                try {
                    await $http.post("/api/user/admin/update/roles", { UserId: user.value.Id, Roles: user.value.Roles });
                    $success("Successfully updated the user's roles.");
                } catch (err) {
                    $error(err.response.data);
                }
            }

            const updatePassword = async () => {
                try {
                    var response = await $http.post("/api/user/admin/update/password", { UserId: user.value.Id, Password: newPassword.value });
                    if (response.data.Id) {
                        $success("Successfully updated the user's password.");
                    } else {
                        $error("Failed to save user's password.");
                    }
                } catch (err) {
                    $error("Failed to save user's password.");
                }
            }

            const sendResetPassword = async () => {
                try {
                    var response = await $http.get("/api/user/forgotpassword/" + user.value.UserName);
                    if (response.data == "The email has been sent to the user.") {
                        $success(response.data)
                    } else {
                        $error(response.data);
                    }
                } catch (err) {
                    $error("Failed to send email.");
                }
            }

            const passwordsMatch = computed(() => {
                return newPassword.value == confirmPassword.value;
            });


            return {
                user,
                reset,
                states,
                roles,
                newPassword,
                confirmPassword,
                saveUser,
                updateRoles,
                updatePassword,
                passwordsMatch,
                sendResetPassword
            }
        }
    }
</script>

<style>
</style>